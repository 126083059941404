import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import Image from "../components/image"
import "../utils/styles/homepage.css"
import BackgroundImage from 'gatsby-background-image'

const bgImage = "data.file.childImageSharp.fluid"

const HomepageHero = () => (
  <StaticQuery
    query={graphql`
      {
        file(name: {eq: "stock-1863880"}) {
          name
          childImageSharp {
            fluid {
      ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data =>
        <div>

      <BackgroundImage className="bg-container"
                       Tag="section"
                       fluid={data.file.childImageSharp.fluid}
                       backgroundColor={`#040e18`}></BackgroundImage>
      <div className="bg-text">

        <Fade left>
          <div>
            <h1>Red Bar Green Bar</h1>
            <br />
            <p>Coming Soon.</p>
          </div>
        </Fade>
      </div>
    </div>
}
  ></StaticQuery>
)

export default HomepageHero
